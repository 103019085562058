import {
    Component,
    OnInit,
    QueryList,
    ViewChildren,
    ViewEncapsulation
} from '@angular/core';
import {PopoverRef} from '../../core/popover/popover-ref';
import {Building, Floor, LocationAreaDetail, Site} from 'src/app/AdminApi';
import {MatSelectChange} from '@angular/material/select';
import {CommonService} from 'src/app/services/common.service';
import * as Chart from 'chart.js';
import {LocalDataService} from 'src/app/services/local-data.service';
import {GraphHelper} from 'src/app/helpers/graph.helper';
import {HttpService} from '../../core/lib/services/http.service';
import {FloorModel} from '../../models/floor.model';
import {RoleTypesEnum} from '../../core/lib/enums/role-types.enum';
import {TranslateService} from '@ngx-translate/core';
import {AlarmDetailsModel} from '../../models/alarm-details.model';
import {AlarmListService} from '../../services/alarm-list.service';
import {LocalDataConstants} from "../../core/lib/utility/local-data.constants";
import {AlarmService} from "../../services/alarm.service";
import {AuthUserModel} from "src/app/core/auth/_models/auth-user.model";
import {AuthService} from "src/app/core/auth/_services/auth.service";
import {NgxPermissionsService} from "ngx-permissions";
import {GraphComponent} from "../../views/partials/graph/graph.component";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {AlarmDismissDialogComponent} from "../alarm-dismiss-dialog/alarm-dismiss.dialog.component";

@Component({
    selector: 'app-care-facility-detail-overlay',
    templateUrl: './care-facility-detail-overlay.component.html',
    styleUrls: ['./care-facility-detail-overlay.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class CareFacilityDetailOverlayComponent implements OnInit {

    building: Building;
    alarmData: AlarmDetailsModel;
    alarmList: AlarmDetailsModel[];
    healthCareOrganisationType: boolean = false;
    locationAreaDetails: LocationAreaDetail;
    public selectedFloor: number;
    public selectedInstallationPlace: string;
    public selectedSite: number;
    public singleSite: boolean;
    public selectedFloorModel: Floor;
    public selectedSiteModel: Site;
    graphHelper: GraphHelper;
    userProfile$: AuthUserModel;
    sensorGraphs = [];
    width = 400;
    height = 150;
    isNurse = false;
    availableData = false;
    public showMissedMeasurementAlarms = false;
    REFRESH_RATE = 5 * 60 * 1000; // 5 mins refresh rate
    @ViewChildren(GraphComponent) graphs: QueryList<GraphComponent>;
    customers: any;
    constructor(private overlayRef: PopoverRef,
                private common: CommonService,
                public auth: AuthService,
                private localData: LocalDataService,
                private alarmListService: AlarmListService,
                private http: HttpService,
                private dialog: MatDialog,
                private alarmService: AlarmService,
                private permissionsService: NgxPermissionsService,
                private translateService: TranslateService,
                ) {
        this.graphHelper = new GraphHelper();
        this.graphHelper.langValue = this.translateService.currentLang;
        this.alarmData = this.overlayRef.data.alarm;
        this.building = this.overlayRef.data.building;
        this.locationAreaDetails = this.overlayRef.data.locationAreaDetails;
        this.selectedFloor = -1;
        this.selectedInstallationPlace = '-1';
        this.selectedFloorModel = new FloorModel();
        this.selectedFloorModel.sites = [];

    }

    ngOnInit(): void {
        this.graphHelper.langValue = this.translateService.currentLang;
        this.checkOrganisationType();
        this.singleSite = this.building.floors.length === 1 && this.building.floors[0].sites.length === 1;
        if (this.building.floors.length > 0) {
            this.selectedFloor = this.building.floors[0].id;
            this.selectedFloorModel = this.building.floors[0];
            if (this.selectedFloorModel.sites.length > 0) {
                this.selectedSite = this.selectedFloorModel.sites[0].id;
                this.selectedSiteModel = this.selectedFloorModel.sites[0];
            }
            this.initSiteGraph();
            this.checkIsNurse();
            this.customers = this.getCustomers();
        }
        this.alarmListService.currentMessage.subscribe(data => this.alarmList = data);
    }

    initSiteGraph() {
       Chart.defaults.global.defaultFontFamily = 'Geon';
       this.graphHelper = new GraphHelper();
       this.graphHelper.langValue = this.translateService.currentLang;
       this.graphHelper.HOURS = 48;
       this.changeHours(this.graphHelper.HOURS);
    }

    changeHours(hours: number) {
        this.graphHelper.HOURS = hours;
        this.localData.set('hours', hours);
        this.updateDashboard();
    }

    updateDashboard() {
        this.graphHelper.checkGraphHours();

        this.graphHelper.langValue = this.translateService.currentLang;
        this.showDashboard(this.graphHelper.HOURS);

        // this.timer = setTimeout(this.updateDashboard.bind(this), this.REFRESH_RATE);
    }

    closeOverlay() {
        this.overlayRef.close();
    }

    floorChangedEvent() {
        this.graphHelper.dataItems = {};
        this.graphHelper.accessibleSensors = [];
        this.graphHelper.accessibleSensorLastMeasurement = [];
        this.sensorGraphs = [];
        // tslint:disable-next-line:triple-equals
        const floor = this.building.floors.filter((x) => (x.id == this.selectedFloor))[0];
        this.selectedSite = -1;
        this.selectedFloorModel = floor;
        this.selectedFloor = floor.id;
        // tslint:disable-next-line:triple-equals
        if (this.selectedFloorModel.sites.length == 1) {
            this.selectedSite = this.selectedFloorModel.sites[0].id;
            this.selectedSiteModel = this.selectedFloorModel.sites[0];
        }
        this.selectedInstallationPlace = '-1';
        this.initSiteGraph();
        this.checkIsNurse();
    }

    getCustomers() {
        const customers = [];
        if (this.selectedSiteModel && this.selectedSiteModel.users.length > 0) {
            for (const user of this.selectedSiteModel.users) {
                // tslint:disable-next-line:triple-equals
                if (user.roles.filter((role) => (role.name.toLowerCase() == RoleTypesEnum.ROLE_CUSTOMER.toLowerCase())).length > 0) {
                    customers.push(user);
                }
            }
        }
        return customers;
    }

    siteChangedEvent($event: MatSelectChange) {
        this.graphHelper.dataItems = {};
        this.graphHelper.accessibleSensors = [];
        this.graphHelper.accessibleSensorLastMeasurement = [];
        this.sensorGraphs = [];
        // tslint:disable-next-line:triple-equals
        const site = this.selectedFloorModel.sites.filter((x) => (x.id == this.selectedSite))[0];
        this.selectedSiteModel = site;
        this.initSiteGraph();
        this.selectedInstallationPlace = '-1';
    }

    async showDashboard(fromTime = null, toTime = null): Promise<void> {
        // tslint:disable-next-line:triple-equals
        const floor = this.building.floors.filter((x) => (x.id == this.selectedFloor))[0];

        // tslint:disable-next-line:triple-equals
        const site = floor.sites.filter((x) => (x.id == this.selectedSite))[0];
        if (site != null) {
            this.graphHelper.accessibleSensors = site.sensors.map((sensor) => sensor.devId);
            this.graphHelper.locationName = site.name;
            site.sensors =  site.sensors.sort((a,b) => {
                return b.comments.localeCompare(a.comments);
            }).reverse();
            this.graphHelper.sensorComments = [];
            for (const sensor of site.sensors) {
                const comment = { id: sensor.devId, comment: sensor.comments };
                this.graphHelper.sensorComments.push(comment);
            }
            this.graphHelper.accessibleSensorLastMeasurement = [];
            if (this.alarmData) {
                this.graphHelper.accessibleSensors.forEach(sensor => {
                    const alarmRes = this.alarmData.alarmList.filter(alarm => {
                        return alarm.measurementType.toLowerCase() == 'lastmeasurement' && alarm.alarmName.toLowerCase() == 'missedmeasurement' && sensor === alarm.devId;
                    });
                    if (alarmRes.length > 0) {
                        this.showMissedMeasurementAlarms = true;
                        const timestamp = alarmRes[0].filters[0].value;
                        var comment = this.graphHelper.sensorComments.find(sc => sc.id == sensor).comment;
                        this.graphHelper.accessibleSensorLastMeasurement.push({comment: comment, devId: sensor, datetime: new Date(timestamp).toLocaleDateString('fi-FI') + " " + new Date(timestamp).toLocaleTimeString('fi-FI')});
                    }
                });
            }
        } else {
            this.graphHelper.accessibleSensors = [];
            this.graphHelper.accessibleSensorLastMeasurement = [];
            this.graphHelper.sensorComments = [];
            this.showMissedMeasurementAlarms = false;
        }


        if (this.graphHelper.accessibleSensors.length > 0) {
            for (const sensor of this.graphHelper.accessibleSensors) {
                this.getSensorData(fromTime, toTime, sensor, site);
            }
        }
    }


    listResult2(sensor: string, data: any, minTime: number): void {
        if (data && data.length > 0) {
            this.availableData = true;
            if (this.graphHelper.processDataItems(data, minTime, sensor)) {
               this.showWholeDashboard(sensor);
           }
        }
    }

    showWholeDashboard(sensor: string): void {
        const sensorData = this.graphHelper.dataItems[sensor];
        const keys = Object.keys(sensorData).sort();
        if (!this.sensorGraphs[sensor]) {
            this.sensorGraphs[sensor] = this.graphHelper.filterKeys(sensorData, keys, sensor, this.alarmData);
        }
    }


    private afterAlarmDismiss() {
        this.graphHelper.dataItems = {};
        this.graphHelper.accessibleSensors = [];
        this.graphHelper.accessibleSensorLastMeasurement = [];
        this.showMissedMeasurementAlarms = false;
        this.sensorGraphs = [];
        this.alarmService.getLocationAlarms(this.locationAreaDetails).subscribe(
            // tslint:disable-next-line:no-shadowed-variable
            (result) => {
                this.alarmList = this.alarmService.getAlarmList(result, this.locationAreaDetails);
                this.alarmListService.changeMessage(this.alarmList);
                this.localData.set(LocalDataConstants.ALARM_LIST, this.alarmList);
                const alarms = this.alarmList.filter(alarm => alarm.location.id == this.building.id);
                if (alarms[0].alarmList.length == 0 ) {
                    this.alarmData = null;
                    this.initSiteGraph();
                } else {
                    this.alarmData = alarms[0];
                    //this.changeHours(this.graphHelper.HOURS);
                    this.checkIsNurse();
                    this.alarmListService.currentMessage.subscribe(data => this.alarmList = data);
                    this.initSiteGraph();
                }
            });
    }

    checkIsNurse() {
        this.userProfile$ = new AuthUserModel();
        this.auth.getUser$().toPromise().then((user) => {
            this.userProfile$ = this.auth.getAuthenticatedUser(user);
            this.isNurse = this.userProfile$.roles.filter((role) => (role.toLowerCase() == RoleTypesEnum.ROLE_NURSE.toLowerCase())).length > 0;
        });
    }

    private checkOrganisationType() {
        this.permissionsService.hasPermission('HEALTH_CARE').then((res) => {
           if (res) {
               this.healthCareOrganisationType = true;
           }
        });
    }

    openDismissMissedMeasurementDialog(sensor: any) {
        const alarmRes = this.alarmData.alarmList.filter(alarm => {
            return alarm.measurementType.toLowerCase() == 'lastmeasurement' && alarm.alarmName == 'missedmeasurement' && sensor.devId === alarm.devId;
        });
        console.log(alarmRes);
        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = '40vw';
        dialogConfig.data = { model: alarmRes[0] };
        dialogConfig.panelClass = 'dismiss-alarm';

        const dismissAlarm = this.dialog.open(AlarmDismissDialogComponent, dialogConfig);

        dismissAlarm.afterClosed().subscribe(
            val => {
                if (val != undefined && val != null) {
                   this.dismissAlarm(val, val.comment);
                }
            }
        );
    }

    dismissAlarm(alarmItem, dismissComment: string) {
        const data = JSON.stringify({
            alarmName: alarmItem.alarmName,
            time: alarmItem.receivedTime,
            // import jquery here or use some document query...
            dismissComment,
        });
        const url = `/dismiss/${alarmItem.devId}`;
        return this.http.post(url, data).then(
            (res) => {
                this.afterAlarmDismiss();
            },
            (error) => {
                alert(`Could not dismiss alarm, got error: ${error}`);
            }
        );
    }

    private getSensorData(fromTime: any, toTime: any, sensor: string, site: Site) {
        console.log("getting sensor data here");
        if (fromTime && toTime) {
            const listPrefix = `/archive/${site.id}/${sensor}/from/${fromTime}/to/${toTime}`;
            this.http.postRequest(listPrefix, {}, null).subscribe((result) => {
                this.listResult2(sensor, result, fromTime);
            }, this.graphHelper.showError.bind(this));
        } else {
            const listPrefix = `/archive/${site.id}/${sensor}/from/`;
            let time;
            if (fromTime == 48 && this.graphHelper.isHenkausDevice(null, sensor, false)) {
                time = this.common.unixUTCSnappedSince(0.15);
            } else {
                time = this.common.unixUTCSnappedSince(fromTime);
            }

            this.http.postRequest(listPrefix + time, {}, null).subscribe((result) => {
                this.listResult2(sensor, result, time);
            }, this.graphHelper.showError.bind(this));
        }
    }

    alarmDismissed($event: boolean) {
        if ($event) {
            this.afterAlarmDismiss();
        }
    }
}
