import {Pipe, PipeTransform} from '@angular/core';
import {CommonService} from 'src/app/services/common.service';
import {Building} from 'src/app/AdminApi';

@Pipe({
    name: 'getCustomerNameForBuilding',
    pure: true
})
export class GetCustomerNameForBuilding implements PipeTransform {

    constructor(private common: CommonService) {

    }
    transform(value: any, args?: any): any {
        return this.getCustomerNameForBuilding(value);
    }

    private getCustomerNameForBuilding(value: Building) {
        return this.common.getCustomerNames(value, true);
    }
}
