<div class="care-facility-overlay-container">
    <img (click)="closeOverlay()" class="close-overlay-img" src="assets/images/cancel_dark.svg"/>
    <mat-form-field color="primary"  *ngIf="!singleSite" ngDefaultControl appearance="outline" class="mat-form-field-fluid floor-select">
        <mat-label class="select-label">{{ "CARE_FACILITY.FLOOR" | translate }}</mat-label>
        <mat-select placeholder="{{ 'CARE_FACILITY.SELECT_FLOOR' | translate }}" [(ngModel)]="selectedFloor" (selectionChange)="floorChangedEvent()">
            <mat-option *ngFor="let floor of building.floors" [value]="floor.id">{{ floor.name }}</mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field color="primary" *ngIf="!singleSite" ngDefaultControl appearance="outline" class="mat-form-field-fluid site-select">
        <mat-label class="select-label">{{ 'CARE_FACILITY.SITE' | translate }}</mat-label>
        <mat-select placeholder="{{ 'CARE_FACILITY.SELECT_SITE' | translate }}" (selectionChange)="siteChangedEvent($event)" [(ngModel)]="selectedSite">
            <mat-option *ngFor="let site of this.selectedFloorModel.sites"  [value]="site.id">{{ site.name }}</mat-option>
        </mat-select>
    </mat-form-field>

    <mat-icon class="next-icn" style="display: none;">keyboard_arrow_right</mat-icon>
    <mat-icon class="prev-icn" style="display: none;">keyboard_arrow_left</mat-icon>
    <div class="container-body">
        <div class="profile-container-left-wrap {{ healthCareOrganisationType ? '' : 'hidden-content'}}">
            <div class="customer-icn">
                <img class="profile-person-img" [src]="'assets/images/icons/general/elders.svg'" alt="measurement type icon" />
                <p class="customer-info-label">{{ 'CARE_FACILITY.CUSTOMERS_INFORMATION' | translate }}</p>
            </div>
            <div class="profile-container">
                <div class="user" *ngFor="let customer of getCustomers()">
                    <div>
                        <h5>{{ customer.name }}</h5>
                        <p class="acc-location">{{ building.streetAddress }}</p>
                    </div>
                    <div class="phone-number-container">
                        <img class="phone-icon" [src]="'assets/images/phone.png'"/>
                        <p class="phone-number">{{customer.phoneNumber}}</p>
                    </div>
                    <div class="traits-container">
                        <h5>{{ "CARE_FACILITY.PLEASE_NOTE" | translate }}</h5>
                        <p class="customer-notes">
                            {{ customer.notes }}
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <div class="alarms-container-measurement {{ healthCareOrganisationType ? '' : 'full-width-container'}}" *ngIf="showMissedMeasurementAlarms">
            <div class="alarm-content">
                <div class="alarm-info alarm-header alarm-lvl-5 missed-measurement-container" *ngFor="let sensor of this.graphHelper.accessibleSensorLastMeasurement">
                    <img class="alarm-img missed-measurement-img" src="assets/images/icons/alarms/lvl-5/general_alarm.svg" alt="measurement type icon" />
                    <p class="alarm-header-info missed-alarm-msg">{{'ALARMS.missedmeasurement' | translate}} {{sensor.comment}}! {{'ALARMS.lastpackagesent' | translate}} {{ sensor.datetime }}</p>
                    <button mat-raised-button (click)="openDismissMissedMeasurementDialog(sensor)" class="alarm-button alarm-button-missed-measurement"><mat-icon class="done-icn">done</mat-icon><span>{{ "ALARM_DETAILS.DISMISS_ALARM" | translate}}</span></button>
                </div>
            </div>
        </div>


        <div class="alarms-container {{ healthCareOrganisationType ? '' : 'full-width-container'}}" *ngIf="this.graphHelper.accessibleSensors.length == 0 || !this.availableData; else showSensors">
            <p class="notfound">{{ "CARE_FACILITY.NO_GRAPHS_AVAILABLE" | translate }}</p>
        </div>
        <ng-template #showSensors>
            <div class="alarms-container {{ healthCareOrganisationType ? '' : 'full-width-container'}} {{showMissedMeasurementAlarms ? 'alarm-container-measurement-active' : ''}}">
                <div class="container-filter">
                    <div class="filter-installation-container">
                        <p class="filter-installation-place">{{ 'CARE_FACILITY.FILTER_ON_INSTALLATION' | translate }}</p>
                    </div>
                    <div class="filter-installation-container">
                        <mat-form-field color="primary" ngDefaultControl appearance="outline" class="mat-form-field-fluid">
                            <mat-label class="select-label">{{ 'CARE_FACILITY.SELECT_INSTALLATION_PLACE' | translate }}</mat-label>
                            <mat-select placeholder="{{ 'CARE_FACILITY.SELECT_INSTALLATION_PLACE' | translate }}" [(ngModel)]="selectedInstallationPlace">
                                <mat-option [value]="'-1'">{{ 'CARE_FACILITY.ALL' | translate }}</mat-option>
                                <mat-option *ngFor="let installationPlace of graphHelper.sensorComments" [value]="installationPlace.id">{{ installationPlace.comment }}</mat-option>
                            </mat-select>

                        </mat-form-field>
                    </div>
                </div>
                <div class="alarm-content" [hidden]="selectedInstallationPlace != '-1' && selectedInstallationPlace != sensor" id="widget-container-{{ sensor }}" *ngFor="let sensor of this.graphHelper.accessibleSensors">
                    <ng-container *ngFor="let keyValue of this.sensorGraphs[sensor]">
                        <app-graph (alarmDismissedEvent)="alarmDismissed($event)" [alarmData]="alarmData"
                                   [graphHelper]="graphHelper"
                                   [selectedSite]="selectedSite"
                                   [selectedFloor]="selectedFloor"
                                   [building]="building"
                                   [sensorGraphs]="sensorGraphs"
                                   [sensor]="sensor"
                                   [customer]="customers"
                                   [keyVal]="keyValue"></app-graph>
                    </ng-container>
                </div>
            </div>
        </ng-template>
    </div>
</div>
