<!--begin::Error-->
<div
  class="d-flex flex-row-fluid flex-column bgi-size-cover bgi-position-center bgi-no-repeat p-10 p-sm-30"
  style="background-image: url('assets/images/error/bg1.jpg'); height: 100%;padding-left: 3%;">
  <!--begin::Content-->
  <h1 class="font-weight-boldest text-dark-75 mt-15" style="font-size: 10rem">404</h1>
  <p class="font-size-h3 text-muted font-weight-normal">
    {{ 'ERROR.SOMETHING_WENT_WRONG' | translate }}
  </p>
  <p style="width: 50%" class="font-size-h3 text-muted font-weight-normal">
    {{ 'ERROR.NO_ROLE_ACCESS' | translate }}
  </p>
  <div *ngIf="auth.userProfile$ | async as _user">
    <p *ngIf="_user !== undefined" class="font-size-h3 text-muted font-weight-normal">
      {{ 'ERROR.REDIRECT' | translate }}
    </p>
    <button style="width: 244px;" *ngIf="_user !== undefined" mat-button (click)="logout()" class="mat-raised-button logout-btn"> {{ 'SIDEBAR.LOG_OUT' | translate}}</button>
  </div>

  <!--end::Content-->
</div>
<!--end::Error-->
