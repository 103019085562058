// Angular
import {
  Component,
  OnInit,
  Inject,
  ChangeDetectionStrategy,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {AlarmListModel} from '../../models/alarm-list.model';
import {TranslateService} from "@ngx-translate/core";

export interface DialogData {
  model: AlarmListModel;
  edit: boolean;
}

@Component({
	selector: 'app-alarm-dismiss-dialog',
	templateUrl: './alarm-dismiss.dialog.component.html',
	changeDetection: ChangeDetectionStrategy.Default,
  styleUrls: ['./alarm-dismiss.dialog.component.scss']
})
export class AlarmDismissDialogComponent implements OnInit {
	// Public properties
  form: FormGroup;
  sensorDismissModel: any;
  hasFormErrors = false;
  viewLoading = false;

    constructor(private fb: FormBuilder,
                public dialogRef: MatDialogRef<AlarmDismissDialogComponent>,
    private translateService: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    ) {}

  initForm() {
    this.form = new FormGroup({
      filters: new FormControl(null, {
        updateOn: 'blur',
      }),
      alarmName: new FormControl(this.data.model.alarmName, {
      updateOn: 'blur',
      }),
      alarmNameTr: new FormControl(this.data.model.alarmNameTr, {
      updateOn: 'blur',
      }),
      devId: new FormControl(this.data.model.devId, {
        updateOn: 'blur',
      }),
      severity: new FormControl(this.data.model.severity, {
        updateOn: 'blur',
      }),
      dismissed: new FormControl(this.data.model.dismissed, {
        updateOn: 'blur',
      }),
      receivedTime: new FormControl(this.data.model.receivedTime, {
        updateOn: 'blur',
      }),
      comment: new FormControl('', {
        updateOn: 'blur',
        validators: [],
      }),
    });
  }

  ngOnInit() {
      this.sensorDismissModel = this.data.model;
      this.initForm();
  }


  /** UI */
  /**
   * Returns component title
   */
  getTitle(): string {
    const addDismissComment = this.translateService.instant('ALARM_DETAILS.ADD_DISMISS_COMMENT');
    // tslint:disable-next-line:no-string-throw
    return addDismissComment;
  }


  close() {
    this.dialogRef.close();
  }

  validate() {
    if (this.form.valid) {
      this.dialogRef.close(this.form.value);
    } else {
      const controls = this.form.controls;
      Object.keys(controls).forEach(controlName =>
        controls[controlName].markAsTouched()
      );
      this.hasFormErrors = true;
    }
  }

}
