export const environment = {
  production: true,
  backendUri: 'https://cloud.vesrat.io',
  logoPath: 'assets/images/vesratio-favicon.png',
  rootUrl: '/',
  clientID: '503rzN1UVdt1BV3m3cFHWmEGelx3JkCS',
  uiUrl: 'https://cloud.vesrat.io',
  auth0: {
    domain: 'vesratio.eu.auth0.com',
    clientId: '503rzN1UVdt1BV3m3cFHWmEGelx3JkCS',
    audience: 'https://cloud.vesrat.io',
    rulesUrl: 'https://cloud.vesrat.io/ovadmin'
  },
  adminUiUrl: 'https://admin.vesrat.io',
};
