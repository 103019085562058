import { Injectable } from '@angular/core';
import createAuth0Client, { RedirectLoginResult } from '@auth0/auth0-spa-js';
import Auth0Client from '@auth0/auth0-spa-js/dist/typings/Auth0Client';
import { from, of, Observable, BehaviorSubject, combineLatest, throwError, Subscription, iif } from 'rxjs';
import { tap, catchError, concatMap, shareReplay, take, map, concatMapTo, filter } from 'rxjs/operators';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import {AuthUserModel} from "../_models/auth-user.model";
import {NgxPermissionsService} from "ngx-permissions";

@Injectable({ providedIn: 'root' })
export class AuthService {
  refreshSub: Subscription;

  appState: any;
  auth0Client$: Observable<Auth0Client> = from(
    createAuth0Client({
      domain: environment.auth0.domain,
      client_id: environment.auth0.clientId,
      scope:
        'openid profile read:testsensors write:testsensors manage:alarm-types manage:alarm-settings view:all-sites dismiss:alarms ' +
        'view:sites manage:relatives manage:nurses manage:sites manage:floors manage:buildings manage:location-areas manage:supervisors ' +
        'manage:organisations manage:customers',
      audience: environment.auth0.audience,
      responseType: 'token id_token',
      redirect_uri: `${environment.uiUrl}`,
      allowSignUp: false,
      cacheLocation: 'localstorage',
      sso: false,
    })
  ).pipe(
    shareReplay(1),
    catchError((err) => throwError(err))
  );

  // set to true once handleAuthCallback has completed
  private initializedSubject = new BehaviorSubject<boolean>(false);

  // emits once handleCallback has completed
  private initialized$ = this.initializedSubject.pipe(
    filter((initialized) => initialized), // only when initialized is true
    take(1) // only emit a single event
  );

  /*isAuthenticated$ = this.auth0Client$.pipe(
      concatMap((client: Auth0Client) => from(client.isAuthenticated())),
      tap(res => this.loggedIn = res)
  );*/

  isAuthenticated$ = this.initialized$.pipe(
    concatMapTo(this.auth0Client$),
    concatMap((client: Auth0Client) => from(client.isAuthenticated()))
  );

  handleRedirectCallback$ = this.auth0Client$.pipe(
    concatMap((client: Auth0Client) => from(client.handleRedirectCallback()))
  );
  // Create subject and public observable of user profile data
  private userProfileSubject$ = new BehaviorSubject<any>(null);
  userProfile$ = this.userProfileSubject$.asObservable();
  // Create a local property for login status
  loggedIn: boolean = null;

  constructor(private router: Router, private permissionsService: NgxPermissionsService) {
    // On initial load, check authentication state with authorization server
    // Set up local auth streams if user is already authenticated
    this.localAuthSetup();
    // Handle redirect from Auth0 login
    this.handleAuthCallback();
  }

  getTokenSilently$(options?): Observable<string> {
    return this.auth0Client$.pipe(
      concatMap((client: Auth0Client) => from(client.getTokenSilently(options))),
      tap((user) => this.userProfileSubject$.next(user))
    );
  }

  login(): Observable<void> {
    return this.auth0Client$.pipe(
      concatMap((client: Auth0Client) =>
        client.loginWithRedirect({
          redirect_uri: `${environment.uiUrl}`,
          appState: { target: '/' },
        })
      )
    );
  }

  /*handleAuthCallback(): Observable<{ loggedIn: boolean; targetUrl: string }> {
    return of(window.location.search).pipe(
      concatMap(params =>
        iif(() => params.includes('code=') && params.includes('state='),
          this.handleRedirectCallback$.pipe(concatMap(cbRes =>
            this.isAuthenticated$.pipe(take(1),
              map(loggedIn => ({ loggedIn,
                targetUrl: cbRes.appState && cbRes.appState.target ? cbRes.appState.target : '/'
              }))))),
          this.isAuthenticated$.pipe(take(1), map(loggedIn => ({ loggedIn, targetUrl: null }))))));
  }*/

  handleAuthCallback(): Observable<{ loggedIn: boolean; targetUrl: string }> {
    const params = window.location.search;
    if (!(params.includes('code=') && params.includes('state='))) {
      // user has not been redirected from login, nothing to do
      this.initializedSubject.next(true);
      return;
    }

    // if the user has been redirected, handle the redirect
    this.handleRedirectCallback$
      .pipe(
        tap(() => this.initializedSubject.next(true)),
        concatMap((cbRes) =>
          this.isAuthenticated$.pipe(
            map((loggedIn) => ({
              loggedIn,
              targetUrl: cbRes.appState && cbRes.appState.target ? cbRes.appState.target : '/',
            }))
          )
        )
      )
      .subscribe((result) => this.router.navigateByUrl(result.targetUrl));
  }

  logout() {
    this.auth0Client$.subscribe((client: Auth0Client) => {
      client.logout({
        client_id: environment.auth0.clientId,
        returnTo: `${environment.uiUrl}`,
      });
    });
  }

  // When calling, options can be passed if desired
  // https://auth0.github.io/auth0-spa-js/classes/auth0client.html#getuser
  getUser$(options?): Observable<any> {
    return this.auth0Client$.pipe(
      concatMap((client: Auth0Client) => from(client.getUser(options))),
      tap((user) => this.userProfileSubject$.next(user))
    );
  }

  localAuthSetup() {
    // This should only be called on app initialization
    // Set up local authentication streams
    const checkAuth$ = this.isAuthenticated$.pipe(
      concatMap((loggedIn) => {
        if (loggedIn) {
          // If authenticated, get user and set in app
          // NOTE: you could pass options here if needed
          return this.getUser$();
        }
        // If not authenticated, return stream that emits 'false'
        return of(loggedIn);
      })
    );
    checkAuth$.subscribe();
  }

  getAuthenticatedUser(user) {
      const userProfile$ = new AuthUserModel();
      userProfile$.roles = user[environment.auth0.rulesUrl + '/roles'];
      userProfile$.name = user.name;
      userProfile$.email = user.email;
      userProfile$.email_verified = user.email_verified;
      userProfile$.updated_at = user.updated_at;
      userProfile$.sub = user.sub;
      userProfile$.picture = user.picture;
      userProfile$.nickname = user.nickname;
      if (user[environment.auth0.rulesUrl + '/user_metadata'] != undefined) {
          userProfile$.notifyEmail = user[environment.auth0.rulesUrl + '/user_metadata'].notify_email;
          userProfile$.notifySms = user[environment.auth0.rulesUrl + '/user_metadata'].notify_sms;
          userProfile$.organisationName = user[environment.auth0.rulesUrl + '/user_metadata'].organisation_name;
          userProfile$.userId = user[environment.auth0.rulesUrl + '/user_metadata'].user_id;
      }
      return userProfile$;
  }

    public hasPermission(role: string) {
        return this.permissionsService.hasPermission(role);
    }

    getPermissions() {
        return this.permissionsService.getPermissions();
    }
}
