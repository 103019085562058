<div class="account-overlay-container">
    <img (click)="closeOverlay()" class="close-overlay-img" src="assets/images/cancel.svg"/>
    <div class="container-header">
        <div class="row first-row">
            <div class="personItem">
                <div class="account-list-logo-container">
                    <img class="profile-img" [src]="'/assets/images/user.png'" alt="measurement type icon" />
                </div>
                <div class="personDetail person-list-title-container">
                    <p class="profileName">
                        <a>{{ "ACCOUNT.ACCOUNT_INFORMATION" | translate }}</a>
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="container-body" [formGroup]="form">
        <div class="row form-content">
            <div class="person-content">
                <div class="row">
                    <p class="person-info person-header">{{ "ACCOUNT.CONTACT_INFORMATION" | translate }}</p>
                </div>
                <div class="row account-info">
                    <div class="form-group">
                        <div class="label-container">
                            <label>{{ "ACCOUNT.FULL_NAME" | translate }}</label>
                        </div>
                        <input type="text" formControlName="name" value="{{ this.userProfile$.name }}" placeholder="{{ 'ACCOUNT.FULL_NAME' | translate }}" />
                        <mat-error
                                *ngIf="form.controls['name'].hasError('required')">
                            {{ 'FORM_VALIDATION.NAME_REQUIRED' | translate }}
                        </mat-error>
                    </div>
                    <div class="form-group">
                        <div class="label-container">
                            <label>{{ "ACCOUNT.PHONE_NUMBER" | translate }}</label>
                        </div>
                        <input type="text" formControlName="phoneNumber" value="{{ this.userProfile$.notifySms }}" placeholder="{{ 'ACCOUNT.PHONE_NUMBER' | translate }}" type="number" />
                    </div>
                    <div class="form-group">
                        <div class="label-container">
                            <label>{{ "ACCOUNT.EMAIL" | translate }}</label>
                        </div>
                        <input type="text" formControlName="email" value="{{ this.userProfile$.email }}" placeholder="{{ 'ACCOUNT.EMAIL' | translate }}" />
                        <mat-error
                                *ngIf="form.controls['email'].hasError('required')">
                            {{ 'FORM_VALIDATION.EMAIL_REQUIRED' | translate }}
                        </mat-error>
                        <mat-error
                                *ngIf="form.controls['email'].hasError('email')">
                            {{ 'FORM_VALIDATION.FIELD_SHOULD_BE_EMAIL' | translate }}
                        </mat-error>
                        <mat-error
                                *ngIf="form.controls['email'].hasError('alreadyInUse')">
                            {{ 'FORM_VALIDATION.EMAIL_USED' | translate }}
                        </mat-error>
                    </div>
                </div>
            </div>
            <div class="person-content">
                <div class="row">
                    <p class="person-info person-header">{{ "ACCOUNT.ORGANISATION_INFORMATION" | translate}}</p>
                </div>
                <div class="row account-info">
                    <div class="form-group">
                        <div class="label-container">
                            <label>{{ "ACCOUNT.ROLES" | translate}}</label>
                        </div>
                        <input type="text" class="readonly-input" readonly="readonly"  value="{{ updateRolesTranslation(this.userProfile$.roles) }}" />
                    </div>
                    <div class="form-group">
                        <div class="label-container">
                            <label>{{ "ACCOUNT.ORGANISATION" | translate}}</label>
                        </div>
                        <input type="text" class="readonly-input" readonly="readonly" value="{{ this.userProfile$.organisationName }}" />
                    </div>
                </div>
            </div>
            <div class="person-content">
                <div class="row">
                    <p class="person-info person-header">{{ "ACCOUNT.CHANGE_PASSWORD" | translate }}</p>
                </div>
                <div class="row account-info">
                    <div class="form-group">
                        <div class="label-container">
                            <label>{{ "ACCOUNT.NEW_PASSWORD" | translate }}</label>
                        </div>
                        <input type="password" formControlName="password" value="" placeholder="{{ 'ACCOUNT.NEW_PASSWORD' | translate }}" />
                        <mat-error
                                *ngIf="form.controls['password'] && form.controls['password'].hasError('required')">
                            {{ 'FORM_VALIDATION.PASSWORD_REQUIRED' | translate }}
                        </mat-error>
                        <mat-error
                                *ngIf="form.controls['password'] && form.controls['password'].hasError('pattern')">
                            {{ 'FORM_VALIDATION.PASSWORD_CHARS_VALIDATION' | translate }}
                        </mat-error>
                    </div>
                    <div class="form-group">
                        <div class="label-container">
                            <label>{{ "ACCOUNT.CONFIRM_PASSWORD" | translate }}</label>
                        </div>
                        <input type="password" value="" formControlName="confirmPassword" placeholder="{{ 'ACCOUNT.CONFIRM_PASSWORD' | translate }}" />
                        <mat-error
                                *ngIf="form.controls['confirmPassword'] && form.controls['confirmPassword'].hasError('required')">
                            {{ 'FORM_VALIDATION.CONFIRM_PASSWORD_REQUIRED' | translate }}
                        </mat-error>
                        <mat-error
                                *ngIf="form.controls['confirmPassword'] && form.controls['confirmPassword'].hasError('pattern')">
                            {{ 'FORM_VALIDATION.CONFIRM_PASSWORD_CHARS_VALIDATION' | translate }}
                        </mat-error>
                        <mat-error
                                *ngIf="form.hasError('passwordMismatch')">
                            {{ 'FORM_VALIDATION.PASSWORDS_DONT_MATCH' | translate }}
                        </mat-error>
                    </div>
                </div>
            </div>
            <div class="row" style="padding: 0">
                <p class="person-info person-header">{{ "USER_ALARM_NOTIFICATION.CHOOSE_SERVICE_LABEL" | translate }}</p>
            </div>
            <div class="row account-info">
                <div class="form-group">
                    <label style="width: 100%;">{{'USER_ALARM_NOTIFICATION.CHOOSE_SERVICE' | translate }}</label>
                    <mat-radio-group
                            formControlName="alarmNotificationOption">
                        <mat-radio-button style="width: 100%;padding-bottom: 6px;padding-top: 6px;" [value]="0">
                            {{'USER_ALARM_NOTIFICATION.SMS' | translate }}
                        </mat-radio-button>
                        <mat-radio-button style="width: 100%" [value]="1">
                            {{'USER_ALARM_NOTIFICATION.WHATSAPP' | translate }}
                        </mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>
            <div class="row account-info">
                <div class="form-group">
                    <label style="width: 100%;">{{'LANGUAGE_PREFERENCE.CHOOSE_LANGUAGE' | translate }}</label>
                    <mat-radio-group
                            formControlName="languagePreference">
                        <mat-radio-button style="width: 100%;padding-bottom: 6px;padding-top: 6px;" [value]="'fi'">
                            {{'LANGUAGE_PREFERENCE.FINNISH' | translate }}
                        </mat-radio-button>
                        <mat-radio-button style="width: 100%" [value]="'en'">
                            {{'LANGUAGE_PREFERENCE.ENGLISH' | translate }}
                        </mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>
        </div>
        <div class="row person-button-row action-buttons">
            <button mat-raised-button class="save-button" (click)="save()">{{ "GENERAL.SAVE" | translate}}</button>
            <button mat-raised-button class="cancel-button" (click)="closeOverlay()">{{ "GENERAL.CANCEL" | translate}}</button>
        </div>
    </div>

</div>
