import {Component, Input, OnInit} from '@angular/core';
import {AlarmDetailsModel} from '../../models/alarm-details.model';
import {PopoverRef} from '../../core/popover/popover-ref';
import {AuthService} from 'src/app/core/auth/_services/auth.service';

@Component({
    selector: 'app-floor-plan-detail-overlay',
    templateUrl: './floor-plan-detail-overlay.component.html',
    styleUrls: ['./floor-plan-detail-overlay.component.scss']
})
export class FloorPlanDetailOverlayComponent implements OnInit {

    alarmData: AlarmDetailsModel;
    width = 400;
    height = 150;

    constructor(private overlayRef: PopoverRef,
                public auth: AuthService) {
        this.alarmData = this.overlayRef.data.alarm;
    }

    closeOverlay() {
        this.overlayRef.close();
    }

    ngOnInit(): void {

    }
}
