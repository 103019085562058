import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {NgxPermissionsService} from "ngx-permissions";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'Vesrat.io OneView';

  constructor(private translate: TranslateService, private permissionsService: NgxPermissionsService) {
    this.permissionsService.hasPermission("HEALTH_CARE").then((res) => {
      if (res) {
        this.translate.setDefaultLang('fi');
        this.translate.use('fi');
      } else {
        this.translate.setDefaultLang('fi-gen');
        this.translate.use('fi-gen');
      }
    });
  }

  ngOnInit(): void {
   /* setTimeout(() => {
      this.translate.setDefaultLang('en');

      // the lang to use, if the lang isn't available, it will use the current loader to get them
      this.translate.use('en');
    }, 500);*/
  }

}
