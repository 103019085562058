import { Component, OnInit } from '@angular/core';
import {AuthService} from 'src/app/core/auth/_services/auth.service';

@Component({
  selector: 'kt-error1',
  templateUrl: './error1.component.html',
  styleUrls: ['./error1.component.scss']
})
export class Error1Component implements OnInit {

  public showLogoutBtn: boolean = false;

  constructor(public auth: AuthService) { }

  ngOnInit() {
  }

  logout() {
    this.auth.logout();
  }

}
