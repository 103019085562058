/**
 * Created by Edison Rama
 */
import {Floor, Site} from "../AdminApi";

export class FloorModel implements Floor {
    sites: Array<Site>;
    id: number;
    name: string;
    version: string | null;
}
