<div class="floor-plan-overlay-container">
    <img (click)="closeOverlay()" class="close-overlay-img" src="assets/images/cancel.svg"/>
    <div class="container-header">
        <div class="row first-row">
            <div class="alarmItem severity2">
                <div class="alarm-list-logo-container">
                    <img class="alarm-img" [src]="'assets/images/icons/alarms/lvl-' + alarmData.alarmList[0].severity + '/' + alarmData.alarmList[0].icon + '.svg'" alt="measurement type icon" />
                </div>
                <div class="alarmDetail alarm-list-title-container">
                    <p class="alarmName">
                        <a>{{ alarmData.location.name }}</a>
                    </p>
                    <p class="hoitaja">
                        Hoitaja 1
                    </p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="alarm-list-logo-container">
                <img class="person-img" [src]="'/assets/images/user.png'" alt="person icon" />
            </div>
            <div class="alarmDetail alarm-list-title-container">
                <p class="hoitaja person-text">
                    Vuorossa: Eturimi Sulkuniemi
                </p>
            </div>
        </div>
    </div>
    <div class="container-body">
        <mat-grid-list cols="11" rowHeight="1:1">
            <mat-grid-tile #liItm class="mat-grid-tile alarm-level-2">
                <mat-grid-tile-header>
                    <div class="subtext-container">
                        <p class="grid-tile-subtext-icons">Test</p>
                    </div>
                </mat-grid-tile-header>
                <mat-grid-tile-footer>
                    <div class="card-icon-container">
                        <img class="grid-tile-alarm-icon" [src]="'assets/images/icons/alarms/lvl-2/door_open.svg'"/>
                    </div>
                </mat-grid-tile-footer>
            </mat-grid-tile>
            <mat-grid-tile>
                <mat-grid-tile-header>
                    <div class="subtext-container">
                        <p class="grid-tile-subtext">K01<br>A02</p>
                    </div>
                </mat-grid-tile-header>
            </mat-grid-tile>
            <mat-grid-tile>test</mat-grid-tile>
            <mat-grid-tile>test</mat-grid-tile>
            <mat-grid-tile>test</mat-grid-tile>
            <mat-grid-tile>test</mat-grid-tile>
            <mat-grid-tile>test</mat-grid-tile>
            <mat-grid-tile>test</mat-grid-tile>
            <mat-grid-tile>test</mat-grid-tile>
            <mat-grid-tile>test</mat-grid-tile>
            <mat-grid-tile>test</mat-grid-tile>

            <mat-grid-tile>test</mat-grid-tile>
            <mat-grid-tile>test</mat-grid-tile>
            <mat-grid-tile>test</mat-grid-tile>
            <mat-grid-tile>test</mat-grid-tile>
            <mat-grid-tile>test</mat-grid-tile>
            <mat-grid-tile>test</mat-grid-tile>
            <mat-grid-tile>test</mat-grid-tile>
            <mat-grid-tile>test</mat-grid-tile>
            <mat-grid-tile>test</mat-grid-tile>
            <mat-grid-tile>test</mat-grid-tile>
            <mat-grid-tile>test</mat-grid-tile>

            <mat-grid-tile>test</mat-grid-tile>
            <mat-grid-tile>test</mat-grid-tile>
            <mat-grid-tile>test</mat-grid-tile>
            <mat-grid-tile>test</mat-grid-tile>
            <mat-grid-tile>test</mat-grid-tile>
            <mat-grid-tile>test</mat-grid-tile>
            <mat-grid-tile>test</mat-grid-tile>
            <mat-grid-tile>test</mat-grid-tile>
            <mat-grid-tile>test</mat-grid-tile>
            <mat-grid-tile>test</mat-grid-tile>
            <mat-grid-tile>test</mat-grid-tile>

            <mat-grid-tile>test</mat-grid-tile>
            <mat-grid-tile>test</mat-grid-tile>
            <mat-grid-tile>test</mat-grid-tile>
            <mat-grid-tile>test</mat-grid-tile>
            <mat-grid-tile>test</mat-grid-tile>
            <mat-grid-tile>test</mat-grid-tile>
            <mat-grid-tile>test</mat-grid-tile>
            <mat-grid-tile>test</mat-grid-tile>
            <mat-grid-tile>test</mat-grid-tile>
            <mat-grid-tile>test</mat-grid-tile>
            <mat-grid-tile>test</mat-grid-tile>

            <mat-grid-tile>test</mat-grid-tile>
            <mat-grid-tile>test</mat-grid-tile>
            <mat-grid-tile>test</mat-grid-tile>
            <mat-grid-tile>test</mat-grid-tile>
            <mat-grid-tile>test</mat-grid-tile>
            <mat-grid-tile>test</mat-grid-tile>
            <mat-grid-tile>test</mat-grid-tile>
            <mat-grid-tile>test</mat-grid-tile>
            <mat-grid-tile>test</mat-grid-tile>
            <mat-grid-tile>test</mat-grid-tile>
            <mat-grid-tile>test</mat-grid-tile>
        </mat-grid-list>
    </div>

</div>
