import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import {Building} from '../AdminApi';
import {TranslateService} from '@ngx-translate/core';
import {RoleTypesEnum} from 'src/app/core/lib/enums/role-types.enum';
import {LocalDataService} from './local-data.service';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  constructor(private sanitizer: DomSanitizer,
              private translateService: TranslateService) {}

  public unixUTCHoursAgoSnapped(hours: number) {
    const d = new Date();
    d.setMinutes(0);
    d.setSeconds(0);
    d.setMilliseconds(0);
    // const time = d.getTime() - 1000 * 3600 * 168; // 1 week
    const time = d.getTime() - 1000 * 3600 * hours; // 24h
    return time;
  }

  public unixUTCSnappedSince(hours: number) {
    if (hours >= 24) {
      return this.unixUTCHoursAgoSnapped(hours);
    }
    const d = new Date();
    d.setMinutes(0);
    d.setSeconds(0);
    d.setMilliseconds(0);
    var newDate = new Date();
    let hourDiff = 0;
    if (hours == 0.15) {
      hourDiff = newDate.getMinutes() - 15;
    } else {
      hourDiff = d.getHours() - hours;
    }
    if (hourDiff >= 0) {
      if (hours == 0.15) {
        newDate.setMinutes(hourDiff);
        return newDate.getTime();
      } else {
        d.setHours(hourDiff);
        return d.getTime();
      }
    } else {
      if (hours == 0.15) {
        newDate.setHours(newDate.getHours() - 1);
        newDate.setMinutes(60 - Math.abs(hourDiff));
        return newDate.getTime();
      } else {
        d.setHours(0);
        return d.getTime() + 1000 * 3600 * hourDiff;
      }
    }
  }

  getCustomerNames(location: Building, trim: boolean) {
    const customerNames = [];
    for (const floor of location.floors) {
      for (const site of floor.sites) {
        for (const user of site.users) {
          if (user.roles.filter((role) => (role.name.toLowerCase() == RoleTypesEnum.ROLE_CUSTOMER.toLowerCase())).length > 0) {
            const userName = user.name.split(' ');
            if (userName.length > 1) {
              const name = userName[0].slice(0, 1) + '.' + userName[1];
              customerNames.push(name);
            } else {
              customerNames.push(name);
            }
          }
        }
      }
    }
    const result = customerNames.join(', ');
    if (trim) {
      if (result.length <= 19) {
        return result;
      } else {
        return result.substring(0, 19);
      }
    } else {
      return result;
    }
  }


  getSeverityIndicator(severity: number) {
    let i = 0;
    const output: Array<number> = [];
    for (i = 0; i < 5; i++) {
      output.push(0);
    }

    for (i = severity; i > 0; i--) {
      output[output.length - i] = 1;
    }

    return output;
  }


  getAlarmIcon(alarmName: string) {
    switch (alarmName) {
      case 'Ei ketään kotona':
      case 'Ei liikettä':
      case 'General alarm':
        return 'general_alarm';
      case 'colow':
      case 'cohigh':
        return 'co_level_change';
      case 'co2low':
      case 'co2high':
        return 'co2_level_change';
      case 'Ovi auki':
        return 'door_open';
      case 'Window open':
        return 'window_open';
      case 'Yövalvominen':
      case 'Yöliikkuminen':
        return 'move_at_night';
      case 'lights on':
        return 'light_on_night';
      case 'Lämpötila alhainen':
      case 'Lämpötila e. alhainen':
        return 'low_temperature';
      case 'Lämpötila korkea':
      case 'Lämpötila e. korkea':
        return 'high_temperature';
      case 'Kuiva sisäilma':
        return 'dry_indoor_air';
      case 'Humid indoor air':
        return 'humid_indoor_air';
      case 'Water leak':
      case 'waterLeak':
      case 'waterleak':
        return 'water_leak';
      case 'Dehydration':
      case 'Empty cup':
        return 'dehydrated';
      case 'Palohälytys':
        return 'fire_alarm';
      case 'dosemissed':
        return "dose_missed";
      case 'technicalerror':
        return "technical_issue";
      case "fall_confirmed":
        return "fall_confirmed";
      case 'batterywarning':
        return "battery_issue";
      default:
        return 'general_alarm';
    }
  }


  translateMeasurementName(measurementName: string) {
   return this.translateService.instant('MEASUREMENTS.' + measurementName);
  }

  translateAlarmName(alarmName: string) {
    return this.translateService.instant('ALARMS.' + alarmName);
  }

  getBuildingSensors(location: Building) {
    let sensors = new Array<string>();
    const allSensors = new Array<string>();
    for (const floor of location.floors) {
      for (const site of floor.sites) {
        sensors = site.sensors.map((sensor) => sensor.devId);
        allSensors.push(...sensors);
      }
    }
    return allSensors;
  }
}
