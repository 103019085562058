<h2 mat-dialog-title style="color: #5b6f74 !important;
    margin-left: 1.5vw;
    margin-bottom: 1vw;
    font-weight: bold;">{{ sensorTitle }}</h2>
<mat-dialog-content class="mat-content-zoom">
    <div style="margin-bottom: 5vw;" id="single-graph-container" class="single-graph-container card card-custom">
        <div class="form" [formGroup]="form">
            <div class="card-body" >
                <div class="alarms-container" *ngIf="accessibleSensors.length == 0; else showSensors">
                    <p class="notfound">{{ "CARE_FACILITY.NO_GRAPHS_AVAILABLE" | translate }}</p>
                </div>
                <ng-template #showSensors>
                    <div class="alarms-container" *ngIf="this.sensorGraphs[sensor] !== undefined">
                        <div class="alarm-content" id="widget-container-{{ sensor }}-zoom">
                            <p *ngIf="this.sensorGraphs[sensor] == undefined" class="notfound">{{ "CARE_FACILITY.NO_RESULTS_SETTINGS" | translate }}</p>
                            <div class="one-graph">
                                <div style="width: 36vw; float: left;">
                                    <div class="dates-container">
                                        <p class="dates-container-label">{{ "CARE_FACILITY.SELECT_DATE_RANGE" | translate }}</p>
                                        <div>
                                            <input formControlName="fromDate" type="date" name="{{key}}_{{sensor}}_from_date-zoom" value="1.1.2020" class="filter-date first-filter-date" placeholder="1.1.2020" />
                                            <span class="filter-date-dash">-</span>
                                            <input formControlName="toDate" type="date" name="{{key}}_{{sensor}}_to_date-zoom" value="31.1.2020"  class="filter-date" placeholder="1.1.2020"/>
                                            <button mat-raised-button color="primary" aria-label="Filter" (click)="filterByDate(key, sensor)" class="filter-btn">
                                                {{ 'GENERAL.SHOW' | translate}}
                                            </button>
                                        </div>
                                        <p  class="date-selector-error" id="{{key}}_{{sensor}}_date-selector-error-zoom" style="display: none;">
                                        </p>
                                    </div>
                                    <div class="graph">
                                        <div class='widget-component-care'>
                                            <p class="no-res-graph" *ngIf="noResults">{{ "CARE_FACILITY.NO_RESULTS" | translate }}</p>
                                            <mat-progress-spinner mode="indeterminate" class="loading-graph" *ngIf="loading"></mat-progress-spinner>
                                            <div *ngIf="!noResults && !loading" style="height: 100%;">
                                                <canvas baseChart
                                                        id="single_{{key}}_{{sensor}}"
                                                        [datasets]="datasets"
                                                        [labels]="labels"
                                                        [options]="options"
                                                        [legend]="chartLegend"
                                                        [chartType]="chartType"
                                                        [plugins]="chartPlugins">
                                                </canvas>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="info-container">
                                    <div style="margin-left: 1vw;">
                                        <p class="hoitaja person-text header-text">{{ 'FORM_VALIDATION.FILTER_RESULTS' | translate }}</p>

                                        <mat-form-field color="primary" ngDefaultControl appearance="outline" class="mat-form-field-fluid filter-select">
                                            <mat-label class="select-label">{{ 'FORM_VALIDATION.CHOOSE_A_FILTER_OPTION' | translate}}</mat-label>
                                            <mat-select value="{{key == 'rpm' || key == 'distance' ? 0.15: 48}}" formControlName="selectHoursFilter" class="select-hours" id="{{key}}-{{sensor}}-select-hours-filter" (selectionChange)="changeTimeSelection($event, key, sensor)">
                                                <mat-option *ngIf="key == 'rpm' || key == 'distance'"  value="0.15">{{ 'FORM_VALIDATION.PAST_15_MINUTES' | translate}}</mat-option>
                                                <mat-option value="8">{{ 'FORM_VALIDATION.PAST_8_HOURS' | translate}}</mat-option>
                                                <mat-option value="12">{{ 'FORM_VALIDATION.PAST_12_HOURS' | translate}}</mat-option>
                                                <mat-option value="24">{{ 'FORM_VALIDATION.PAST_24_HOURS' | translate}}</mat-option>
                                                <mat-option  *ngIf="key != 'rpm' && key != 'distance'"  value="48">{{ 'FORM_VALIDATION.PAST_48_HOURS' | translate}}</mat-option>
                                                <mat-option  *ngIf="key != 'rpm' && key != 'distance'"  value="168">{{ 'FORM_VALIDATION.PAST_7_DAYS' | translate}}</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="graph-text" *ngIf="!this.graphHelper.isAddozDevice(key) && !this.graphHelper.isVayyarDevice(key, null, sensor) && !this.graphHelper.isSosButtonDevice(key)">
                                    <div class="info-container">
                                        <div class="info-img-holder">
                                            <img class="graph-info-img" [src]="'assets/images/settings.png'" alt="measurement type icon" />
                                        </div>
                                        <div class="statistics-holder">
                                            <p class="statistics-item">{{ "CARE_FACILITY.HIGHEST" | translate }} <span>{{maxVal}}</span></p>
                                            <p class="statistics-item">{{ "CARE_FACILITY.AVERAGE" | translate }} <span>{{meanVal}}</span></p>
                                            <p class="statistics-item">{{ "CARE_FACILITY.LOWEST" | translate }} <span>{{minVal}}</span></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </div>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button type="button" class="close-btn"  mat-raised-button  (click)="downloadCanvas($event)" cdkFocusInitial
    >
        {{ 'GENERAL.DOWNLOAD_GRAPH' | translate }}
    </button>
    <button type="button"  mat-dialog-close class="close-btn"  mat-raised-button  (click)="close()" cdkFocusInitial
    >
        {{ 'GENERAL.CLOSE' | translate }}
    </button>
</mat-dialog-actions>

