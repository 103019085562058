import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {AuthGuard} from 'src/app/core/auth/_guards/auth.guard';
import {LayoutComponent} from './views/layout.component';
import {MapComponent} from './views/map/map.component';
import {CardComponent} from './views/card/card.component';
import {CallbackComponent} from './callback/callback.component';

const routes: Routes = [
  { path: 'error', loadChildren: () => import('./views/error/error.module').then(m => m.ErrorModule) },
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'map',
        component: MapComponent
      },
      {
        path: 'card',
        component: CardComponent
      },
      {
        path: 'callback',
        component: CallbackComponent
      },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
