import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {PopoverRef} from '../../core/popover/popover-ref';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {UserService} from '../../services/user.service';
import {TranslateService} from '@ngx-translate/core';
import {AlarmNotificationServiceTypeEnum} from '../../core/lib/enums/alarm-notification-service-type.enum';
import {UserAlarmNotificationModel} from '../../models/user-alarm-notification.model';
import {UserDataModel} from '../../models/user-data.model';
import {ResponseModel} from '../../core/lib/model/response.model';

@Component({
    selector: 'app-notifications-overlay',
    templateUrl: './notifications-overlay.component.html',
    styleUrls: ['./notifications-overlay.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class NotificationsOverlayComponent implements OnInit {
    form: FormGroup;
    authId: string;
    user: UserDataModel;

    constructor(private overlayRef: PopoverRef,  private fb: FormBuilder, private userService: UserService, private translateService: TranslateService) {
        this.authId = this.overlayRef.data;
    }

    closeOverlay() {
        this.overlayRef.close();
    }

    save() {
        const user = new UserDataModel();
        const controls = this.form.controls;
        if (this.form.invalid) {
            Object.keys(controls).forEach(controlName => {
                const innerControls = this.getSchedules().controls;
                if (controlName == 'alertSchedule') {
                    Object.keys(innerControls).forEach(innerControlName => {
                        const innerFormControl = innerControls[innerControlName].controls;
                        Object.keys(innerFormControl).forEach(innerFormControlName => {
                            innerFormControl[innerFormControlName].markAsTouched();
                        });
                    });
                } else {
                    controls[controlName].markAsTouched();
                }
            });
            return;
        }

        user.userAlarmNotification = [];
        let alarmNotificationSmsModel = new UserAlarmNotificationModel();
        let alarmNotificationEmailModel = new UserAlarmNotificationModel();

        alarmNotificationSmsModel.active = controls.smsAlertNotification.value;
        alarmNotificationSmsModel.service = AlarmNotificationServiceTypeEnum.SERVICE_SMS;
        alarmNotificationSmsModel = this.evaluateAlarmSchedule(alarmNotificationSmsModel, (controls.alertSchedule as FormArray).controls[1]);

        alarmNotificationEmailModel.active = controls.emailAlertNotification.value;

        console.log(alarmNotificationEmailModel.active);
        alarmNotificationEmailModel.service = AlarmNotificationServiceTypeEnum.SERVICE_EMAIL;
        alarmNotificationEmailModel = this.evaluateAlarmSchedule(alarmNotificationEmailModel, (controls.alertSchedule as FormArray).controls[0]);
        user.userAlarmNotification.push(alarmNotificationEmailModel);
        user.userAlarmNotification.push(alarmNotificationSmsModel);
        user.authId = this.authId;

        if (this.form.valid) {
            this.overlayRef.close(user);
        }
    }

    ngOnInit(): void {
        this.user = new UserDataModel();
        this.initForm();
        this.userService.getUserByAuthId(this.authId).then((res: ResponseModel) => {
            if (res.success) {
                this.user = res.result;
                console.log(this.user);
                this.initForm();
            }
        });
    }

    initForm() {
        let emailAlert = 1;
        let smsAlert = 1;
        if (this.user.userAlarmNotification != null && this.user.userAlarmNotification.length > 0) {
            const filterAlarmNotiEmail = this.user.userAlarmNotification.filter(userAlarm => userAlarm.service == AlarmNotificationServiceTypeEnum.SERVICE_EMAIL && userAlarm.active );
            const filterAlarmNotiSms = this.user.userAlarmNotification.filter(userAlarm => userAlarm.service == AlarmNotificationServiceTypeEnum.SERVICE_SMS && userAlarm.active );
            if (filterAlarmNotiEmail.length == 0 ) {
                emailAlert = 0;
            }
            if (filterAlarmNotiSms.length == 0) {
                smsAlert = 0;
            }
        }
        this.form = this.fb.group({
            emailAlertNotification: [emailAlert, Validators.required],
            smsAlertNotification: [smsAlert, Validators.required],
            alertSchedule: this.fb.array([this.createAlertScheduleFormGroup(AlarmNotificationServiceTypeEnum.SERVICE_EMAIL), this.createAlertScheduleFormGroup(AlarmNotificationServiceTypeEnum.SERVICE_SMS)])
        });
        this.emailAlertNotificationChanged();
        this.smsAlertNotificationChanged();

    }

    private createAlertScheduleFormGroup(service: number): FormGroup {
        let model = null;
        let validationKey = '';
        if (this.user.id) {
            if (this.user.userAlarmNotification.filter(ua => ua.service == service).length > 0) {
                model = this.user.userAlarmNotification.filter(ua => ua.service == service)[0];
            }
        }

        if (service == AlarmNotificationServiceTypeEnum.SERVICE_EMAIL) {
            validationKey = 'emailAlertNotification';
        } else {
            validationKey = 'smsAlertNotification';
        }

        return new FormGroup({
            mon: new FormControl(model ? model.mon : ''),
            tue: new FormControl(model ? model.tue : ''),
            wed: new FormControl(model ? model.wed : ''),
            thur: new FormControl(model ? model.thur : ''),
            fri: new FormControl(model ? model.fri : ''),
            sat: new FormControl(model ? model.sat : ''),
            sun: new FormControl(model ? model.sun : ''),
            from: new FormControl(model ? model.fromTime : '', this.requiredIfValidator(() => this.form.get(validationKey).value)),
            to: new FormControl(model ? model.toTime : ''),
        });
    }

    private evaluateAlarmSchedule(model: UserAlarmNotificationModel, control: any) {
        model.mon = control.value.mon;
        model.tue = control.value.tue;
        model.wed = control.value.wed;
        model.thur = control.value.thur;
        model.fri = control.value.fri;
        model.sat = control.value.sat;
        model.sun = control.value.sun;
        model.fromTime = control.value.from;
        model.toTime = control.value.to;
        return model;
    }

    requiredIfValidator(predicate) {
        return (formControl => {
            if (!formControl.parent) {
                return null;
            }
            if (predicate()) {
                return Validators.required(formControl);
            }
            return null;
        });
    }

    isServiceChecked(service: string) {
        if (this.form != undefined) {
            return this.form.controls[service].value == 1;
        }
        return false;
    }

    checkIfTimingCorrect(from: string, to: string, validationKey: string) {
        return (group: FormGroup) => {
            if (this.isServiceChecked(validationKey)) {
                let fromInput = group.controls[from],
                    toInput = group.controls[to];
                if ((fromInput != undefined && fromInput.value != '') && (toInput != undefined && toInput.value != '')){
                    if (fromInput.value >= toInput.value) {
                        toInput.setErrors({timingMismatch: true});
                        return { timingMismatch: true };
                    } else {
                        return null;
                    }
                } else {
                    return null;
                }
            } else {
                return null;
            }
        }
    }

    smsAlertNotificationChanged() {
        const isChecked = this.isServiceChecked('smsAlertNotification');
        if (isChecked) {
            this.getSchedules().controls[1].get('to').setValidators([Validators.required]);
            this.getSchedules().controls[1].get('from').setValidators([Validators.required]);
            //this.getSchedules().controls[1].setValidators([this.checkIfTimingCorrect('from', 'to', 'smsAlertNotification')]);
            this.getSchedules().controls[1].updateValueAndValidity();
            this.form.updateValueAndValidity();
            this.getSchedules().updateValueAndValidity();
        } else {
            this.getSchedules().controls[1].get('to').clearValidators();
            this.getSchedules().controls[1].get('from').clearValidators();
            this.getSchedules().controls[1].clearValidators(); // or clearValidators()
            this.getSchedules().controls[1].reset();
            this.getSchedules().controls[1].updateValueAndValidity();
            this.form.updateValueAndValidity();
            this.getSchedules().updateValueAndValidity();
        }
    }

    getSchedules() {
        return this.form.get('alertSchedule') as FormArray;
    }    
    
    emailAlertNotificationChanged() {
        const isChecked = this.isServiceChecked('emailAlertNotification');
        if (isChecked) {
            this.getSchedules().controls[0].get('to').setValidators([Validators.required]);
            this.getSchedules().controls[0].get('from').setValidators([Validators.required]);
            //this.getSchedules().controls[0].setValidators([this.checkIfTimingCorrect('from', 'to', 'emailAlertNotification')]);
            this.getSchedules().controls[0].updateValueAndValidity();
            this.form.updateValueAndValidity();
            this.getSchedules().updateValueAndValidity();
        } else {
            this.getSchedules().controls[0].get('to').clearValidators();
            this.getSchedules().controls[0].get('from').clearValidators();
            this.getSchedules().controls[0].clearValidators(); // or clearValidators()
            this.getSchedules().controls[0].reset();
            this.getSchedules().controls[0].updateValueAndValidity();
            this.form.updateValueAndValidity();
            this.getSchedules().updateValueAndValidity();
        }
    }
}
