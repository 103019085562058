import { Injectable, Injector } from '@angular/core';
import { Overlay, ConnectionPositionPair, PositionStrategy, OverlayConfig } from '@angular/cdk/overlay';
import { PortalInjector, ComponentPortal } from '@angular/cdk/portal';
import { PopoverRef, PopoverContent } from './popover-ref';
import { PopoverComponent } from './popover.component';

export type PopoverParams<T> = {
    width?: string | number;
    height?: string | number;
    origin: HTMLElement;
    content: PopoverContent;
    data?: T;
}

@Injectable({
    providedIn: 'root'
})
export class Popover {
    constructor(private overlay: Overlay, private injector: Injector) { }

    open<T>({ origin, content, data}: PopoverParams<T>): PopoverRef<T> {
        const overlayRef = this.overlay.create(this.getOverlayConfig({ origin}));
        const popoverRef = new PopoverRef<T>(overlayRef, content, data);

        const injector = this.createInjector(popoverRef, this.injector);
        overlayRef.attach(new ComponentPortal(PopoverComponent, null, injector));

        return popoverRef;
    }

    private getOverlayConfig({ origin}): OverlayConfig {
        return new OverlayConfig({
            hasBackdrop: true,
            backdropClass: 'popover-backdrop',
            positionStrategy: this.getOverlayPosition(origin),
            scrollStrategy: this.overlay.scrollStrategies.block(),
        });
    }

    private getOverlayPosition(origin: HTMLElement): PositionStrategy {
        const positionStrategy = this.overlay.position()
            .global()
            .left()
            .centerVertically();

        return positionStrategy;
    }

    createInjector(popoverRef: PopoverRef, injector: Injector) {
        const tokens = new WeakMap([[PopoverRef, popoverRef]]);
        return new PortalInjector(injector, tokens);
    }
}