<div id="alarm-dismiss-dialog" class="card card-custom "
    [ngClass]="{ 'card-body-progress' : viewLoading }">

    <div class="card-header" *ngIf="sensorDismissModel">
        <div class="card-title">
            <h3 class="card-label title-dismiss-txt">{{getTitle()}}</h3>
        </div>
    </div>
    <div class="form">
        <div class="card-body" *ngIf="sensorDismissModel" [formGroup]="form">

          <div class="mb-2">
              <mat-form-field appearance="outline" class="mat-form-field-fluid" style="width: 100% !important;margin-bottom: 1vw; font-size: 1vw;">
                  <mat-label>{{ "ALARM_DETAILS.COMMENT" | translate }}</mat-label>
                  <textarea class="comment-textarea" matInput rows="3" cols="40" placeholder="{{ 'ALARM_DETAILS.COMMENT' | translate }}" formControlName="comment"></textarea>
                  <mat-error
                          *ngIf="form.controls['comment'] && form.controls['comment'].hasError('required')">
                      {{ 'ALARM_DETAILS.COMMENT_REQUIRED' | translate }}
                  </mat-error>
                  <mat-hint align="start" id="comment-hint"> {{ 'ALARM_DETAILS.ENTER_COMMENT' | translate }}
                  </mat-hint>
              </mat-form-field>
          </div>
        </div>
        <div class="card-footer">
            <div class="d-flex justify-content-between">
                <div class="mr-2"></div>
                <div style="float: right;">
                    <button type="button"  id="cancel-btn" mat-raised-button  (click)="close()" cdkFocusInitial
                        >
                        {{ 'GENERAL.CANCEL' | translate }}
                    </button>&nbsp;
                    <button type="button" id="dismiss-alarm-btn" (click)="validate()" mat-raised-button
                        color="primary" [disabled]="viewLoading">
                        {{ 'ALARM_DETAILS.DISMISS_ALARM' | translate }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
