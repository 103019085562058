<header class="header">
  <div id="hmbrg-box" class="nav-info-box-hmbrg">
    <div class="map-select" (click)="toggleMenu()">
      <img class="accordion-icon-hmb" src="/assets/images/icons/general/hamburger.svg"/>
    </div>
  </div>
  <div id="map-box" class="nav-info-box">
    <div class="map-select" (click)="toggleLocationSection()">
      <img class="accordion-icon" src="/assets/images/map_view_2.png" />
      <button class="accordion {{locationBtnActive ? 'active' : ''}}">{{selectedLocation}}</button>
      <img class="accordion-icon panel-action-icn" src="{{locationBtnActive ? 'assets/images/icons/general/close_icn.svg' : 'assets/images/icons/general/expand_more_icn.svg'}} " />
    </div>
    <div class="panel {{locationBtnActive ? 'panel-opened' : 'panel-closed'}}">
      <ul class="panel-list">
        <li #locationElement (click)="locationChanged(locationElement)" class="panel-list-item" *ngFor="let item of panLocations; let locationIndex = index" [attr.data-id]="locationIndex">
          <img *ngIf="locationIndex == selectedLocationIndex" class="selected-item-icon" src="/assets/images/ok.png"/>
          <p class="location-text panel-list-item-text {{locationIndex == selectedLocationIndex ? 'panel-list-item-selected' :''}}">{{item.name}}</p>
        </li>
        <!--<li class="location-menu-list-item">
          <button mat-raised-button class="location-menu-btn">NÄYTÄ HAKUTULOS</button>
        </li>
        <li class="delete-menu-list-item">
          <button mat-button class="delete-menu-btn">
            <img src="/assets/images/trashbin.png"/><span class="delete-menu-btn-text">Nolla valinnat</span></button>
        </li>-->
      </ul>
    </div>
  </div>
  <div id="user-box" class="nav-info-box">
    <div class="map-select" (click)="toggleProfileSection()">
      <img class="accordion-icon" src="/assets/images/user.png" />
      <ng-container *ngIf="auth.userProfile$ | async as _user">
        <button class="profile-name accordion">{{'HEADER.GREETING' | translate }} {{ userProfile$.nickname }}</button>
      </ng-container>
      </div>
    <div class="panel">
    </div>
  </div>

  <app-view-selector></app-view-selector>
  <app-language-selector [languages]="languages"></app-language-selector>
<!--  <div id="nurse-box" class="nav-info-box">
    <div class="nurse-info">
      <p class="nurse-info-label">NÄYTETÄÄN HOITOPAIKAT HOITAJALLE: </p>
      <p class="nurse-info-name">{{'LIISA PENNANEN'}}</p>
    </div>
    <div class="panel">
    </div>
  </div>-->
</header>
