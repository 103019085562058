import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivate } from '@angular/router';
import {iif, Observable, of} from 'rxjs';
import {concatMap, map, mapTo, mergeMap, tap} from 'rxjs/operators';
import {AuthService} from '../_services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private auth: AuthService) {}

  canActivate(
      next: ActivatedRouteSnapshot,
      state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean | UrlTree> | boolean {
    return this.auth.isAuthenticated$.pipe(
        mergeMap(loggedIn => {
          if (!loggedIn) {
            return this.auth.login().pipe(mapTo(false));
          }
          return of(true);
        }),
    );
  }
}
