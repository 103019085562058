<div id="language-box" class="nav-info-box-language">
	<!--<div class="map-select" (click)="toggleLanguageSelection()">
		<img class="accordion-icon selected-lng" src="{{ selectedLanguageFlag }}" />
	</div>-->


	<div class="nav-info-box-language nav-info-box-language-inner">
		<div class="map-select" (click)="toggleLanguageSelection()">
			<img style="margin-left: 0.25vw;" class="accordion-icon-hmb" src="{{ selectedLanguageFlag }}"/>
		</div>
	</div>
	<div class="panel {{ languageBtnActive ? 'panel-opened' : 'panel-closed'}}">
		<ul class="panel-list">
			<li #locationElement (click)="languageChanged(locationElement)" class="panel-list-item" [attr.data-id]="notSelectedLanguage.lang">
				<div class="language-content">
					<img style="margin-left: 0.25vw;" class="selected-flag-icon" src="{{notSelectedLanguage.flag}}"/>
				</div>
			</li>
		</ul>
	</div>
</div>
