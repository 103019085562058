// Angular
import { Component, HostBinding, OnInit, Input } from '@angular/core';
import {NavigationEnd, NavigationStart, Router} from '@angular/router';
// RxJS
import { filter } from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
// Translate

@Component({
  selector: 'app-view-selector',
  templateUrl: './view-selector.component.html',
  styleUrls: ['./view-selector.component.scss']
})
export class ViewSelectorComponent implements OnInit {
  // Public properties
  @Input() iconType: '' | 'brand';

  selectedView: string;

  /**
   * Component constructor
   *
   * @param translationService: TranslationService
   * @param router: Router
   */
  constructor(private translationService: TranslateService, private router: Router) {
    this.router.events.subscribe(this.routeChange.bind(this));
  }

  private routeChange(val: any) {
    if (val instanceof NavigationEnd && val.urlAfterRedirects == '/card') {
      this.selectedView = 'card';
    }
    if (val instanceof NavigationEnd && val.urlAfterRedirects.indexOf('/map') !== -1) {
      this.selectedView = 'map';
    }
  }

  /**
   * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
   */

  /**
   * On init
   */
  ngOnInit() {
  }


  changeView(view: string) {
    if (view !== this.selectedView) {
      this.selectedView = view;
      if (this.selectedView == 'map') {
        this.router.navigate(['/map']);
      } else {
        this.router.navigate(['/card']);
      }
    }
  }
}
