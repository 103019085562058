import {Component, OnInit, AfterViewInit, Input, OnDestroy} from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { LocalDataService } from 'src/app/services/local-data.service';
import { Router } from '@angular/router';
import { PanLocationModel } from '../models/pan-location.model';
import {Popover} from '../core/popover/popover.service';
import {LocationService} from '../services/location.service';
import {ResponseModel} from '../core/lib/model/response.model';
import {LocationAreaNameModel} from '../models/location-area-name.model';
import {LocalDataConstants} from '../core/lib/utility/local-data.constants';
import {TranslateService} from '@ngx-translate/core';
import {NgxPermissionsService} from "ngx-permissions";
import {RoleTypesEnum} from "../core/lib/enums/role-types.enum";
import {environment} from "../../environments/environment";
import {OrganisationTypesEnum} from "../core/lib/enums/organisation-types.enum";
import {AuthService} from "../core/auth/_services/auth.service";
import {AuthUserModel} from "../core/auth/_models/auth-user.model";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [
    trigger('slideInOut', [
      state(
        'in',
        style({
          transform: 'translate3d(4px, 0, 0)',
        })
      ),
      state(
        'out',
        style({
          transform: 'translate3d(-100%, 0, 0)',
        })
      ),
      transition('in => out', animate('400ms ease-in-out')),
      transition('out => in', animate('400ms ease-in-out')),
    ]),
  ],
})
export class HeaderComponent implements OnInit, AfterViewInit, OnDestroy {
  // tslint:disable-next-line:no-input-rename
  @Input('sideNavBar')
  sideNavbar;

  languages : any;
  appTitle = 'Cloud';
  userProfile$ = new AuthUserModel();

  menuState = 'out';
  locationBtnActive = false;
  profileBtnActive = false;
  selectedLocation = '';
  selectedLocationIndex = -1;

  panLocations: LocationAreaNameModel[];

  constructor(
    private localData: LocalDataService,
    public auth: AuthService,
    public translateService: TranslateService,
    private router: Router,
    private popper: Popover,
    private permissionsService: NgxPermissionsService,
    private locationService: LocationService
  ) {}

  ngOnInit() {
    setTimeout(() => this.initProfile(), 1000);
   /* this.initProfile();*/
  }

  toggleProfileSection() {
    this.profileBtnActive = !this.profileBtnActive;
  }

  toggleLocationSection() {
    this.locationBtnActive = !this.locationBtnActive;
  }


  locationChanged(event) {
    this.selectedLocationIndex = event.getAttribute('data-id');
    this.localData.set(LocalDataConstants.SELECTED_LOCATION, this.selectedLocationIndex);
    this.selectedLocation = this.panLocations[this.selectedLocationIndex].name;
    this.locationBtnActive = false;
    this.navigateTo(this.selectedLocationIndex);
  }

  ngAfterViewInit() {
    this.initLocationList();
  }

  changeLanguage(language: string) {
    this.translateService.use(language);
  }

  toggleMenu() {
    this.sideNavbar.toggle();
  }

  public navigateTo(value: any) {
    if (this.panLocations !== undefined && this.panLocations.length > 0) {
      const loc = this.panLocations[value];
      const panLoc = new PanLocationModel();
      panLoc.name = loc.name;
      panLoc.lat = loc.latitude;
      panLoc.lon = loc.longitude;
      panLoc.zoom = 12;
      if (value !== undefined && value !== -1) {
        this.router.navigate(['/map', panLoc]);
      }
    } else {
      this.router.navigate(['/map']);
    }

    return false;
  }

  initLocationList() {
      this.locationService.getLocationAreaNames().then((res: ResponseModel) => {
        if (res.success) {
           this.panLocations = res.result;
           this.panLocations = this.panLocations.sort((a,b) => {
             return b.name.localeCompare(a.name);
           }).reverse();
           this.localData.set(LocalDataConstants.LOCATION_NAMES, this.panLocations);
           if (this.panLocations.length > 0) {
             this.selectedLocation = this.panLocations[0].name;
             this.selectedLocationIndex = 0;
             this.localData.set(LocalDataConstants.SELECTED_LOCATION, this.selectedLocationIndex);
             this.navigateTo(0);
           } else {
             this.navigateTo(0);
           }
        } else {
          console.log(res.message);
        }
      });
  }

  private initProfile() {
    this.userProfile$ = new AuthUserModel();
    this.auth.getUser$().toPromise().then((user) => {
      this.userProfile$ = this.auth.getAuthenticatedUser(user);
      this.loadRolesWithPermissions();
    });
  }

  loadRolesWithPermissions() {
    this.auth.getUser$().toPromise().then((user) => {
      if ((!user || user.length === 0) || (!user[environment.auth0.rulesUrl + '/roles'] || user[environment.auth0.rulesUrl + '/roles'].length == 0)) {
        return;
      }

      this.permissionsService.flushPermissions();
      this.permissionsService.loadPermissions(user[environment.auth0.rulesUrl + '/roles']);
      var organisationType = user[environment.auth0.rulesUrl + '/user_metadata'].organisation_type;
      if (organisationType == null || organisationType == undefined || organisationType == OrganisationTypesEnum.TYPE_HEALTH_CARE) {
        this.permissionsService.addPermission("HEALTH_CARE");
      }
      this.checkLanguagePermissions();
      this.checkPermissions();
    });
  }

  private checkPermissions() {
    this.auth.isAuthenticated$.subscribe((res) => {
      if (res) {
        this.auth.hasPermission(RoleTypesEnum.ROLE_NURSE).then((resultsNurse) => {
          if (!resultsNurse) {
            this.auth.hasPermission(RoleTypesEnum.ROLE_SUPERVISOR).then((resultSupervisor) => {
              if (!resultSupervisor) {
                this.auth.hasPermission(RoleTypesEnum.ROLE_CUSTOMER_RELATIVE).then((resultRelative) => {
                  if (!resultRelative) {
                    this.router.navigate(['error']);
                  }
                });
              }
            });
          }
        });
      }
    });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    this.permissionsService.flushPermissions();
  }

  private initHealthCareLanguages() {
    this.languages = [
      {
        lang: 'en',
        name: this.translateService.instant('LANGUAGES.EN'),
        flag: './assets/images/icons/flags/united-kingdom-flag-icon-256.png'
      },
      {
        lang: 'fi',
        name: this.translateService.instant('LANGUAGES.FI'),
        flag: './assets/images/icons/flags/finland-flag-icon-256.png'
      },
    ];
  }

  private initGeneralLanguages() {
    this.languages = [
      {
        lang: 'en-gen',
        name: this.translateService.instant('LANGUAGES.EN'),
        flag: './assets/images/icons/flags/united-kingdom-flag-icon-256.png'
      },
      {
        lang: 'fi-gen',
        name: this.translateService.instant('LANGUAGES.FI'),
        flag: './assets/images/icons/flags/finland-flag-icon-256.png'
      },
    ];
  }

  private checkLanguagePermissions() {
    this.permissionsService.hasPermission("HEALTH_CARE").then((res) => {
      if (res) {
        this.auth.getUser$().toPromise().then((user) => {
          let language = '';
          if ((!user || user.length === 0) || (!user[environment.auth0.rulesUrl + '/roles'] || user[environment.auth0.rulesUrl + '/roles'].length == 0)) {
           language = 'fi';
          } else {
            var languagePreference = user[environment.auth0.rulesUrl + '/user_metadata'].languagePreference;
            language = languagePreference && languagePreference == 'en' ? 'en' : 'fi';
          }

          this.translateService.use(language).subscribe((res) => {
            this.initHealthCareLanguages();
          });
        });
      } else {
        this.auth.getUser$().toPromise().then((user) => {
          let language = '';
          if ((!user || user.length === 0) || (!user[environment.auth0.rulesUrl + '/roles'] || user[environment.auth0.rulesUrl + '/roles'].length == 0)) {
            language = 'fi';
          } else {
            var languagePreference = user[environment.auth0.rulesUrl + '/user_metadata'].languagePreference;
            language = languagePreference && languagePreference == 'en' ? 'en' : 'fi';
          }

          this.translateService.setDefaultLang(language + '-gen');
          this.translateService.use(language + '-gen').subscribe((res) => {
            this.initHealthCareLanguages();
            this.initGeneralLanguages();
          });
        });
      }
    });
  }
}
