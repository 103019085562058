<div class="one-graph" [formGroup]="form">
    <div class="row">
        <div class="alarm-info alarm-header" #headerTitle id="{{keyVal.key}}-{{sensor}}-header-title" [innerHTML]="sensorKeyHeaderTitle"></div>
    </div>
    <div class="row graph-info">
        <div class="graph">
            <div class='widget-component-care'>
                <p class="no-res-graph" *ngIf="noResults">{{ "CARE_FACILITY.NO_RESULTS" | translate }}</p>
                <mat-progress-spinner mode="indeterminate" class="loading-graph" id='{{keyVal.key}}-{{sensor}}-loading' *ngIf="loading"></mat-progress-spinner>
                <div *ngIf="!noResults && !loading">
                    <canvas baseChart
                            id="{{keyVal.key}}_{{sensor}}"
                            [datasets]="datasets"
                            [labels]="labels"
                            [options]="options"
                            [legend]="chartLegend"
                            [chartType]="chartType"
                            [plugins]="chartPlugins">
                    </canvas>
                </div>
            </div>
            <div class="dates-container">
                <p class="dates-container-label">{{ "CARE_FACILITY.SELECT_DATE_RANGE" | translate }}</p>
                <div>
                    <input formControlName="fromDate" type="date" name="{{keyVal.key}}_{{sensor}}_from_date" value="1.1.2020" class="filter-date first-filter-date" placeholder="1.1.2020" />
                    <span class="filter-date-dash">-</span>
                    <input formControlName="toDate" type="date" name="{{keyVal.key}}_{{sensor}}_to_date" value="31.1.2020"  class="filter-date" placeholder="1.1.2020"/>
                    <button mat-raised-button color="primary" aria-label="Filter" (click)="filterByDate(keyVal.key, sensor)" class="filter-btn">
                        {{ 'GENERAL.SHOW' | translate}}
                    </button>
                </div>
                <p *ngIf="dateSelectionError" class="date-selector-error" id="{{keyVal.key}}_{{sensor}}_date-selector-error">
                {{dateSelectionErrorText}}
                </p>
            </div>

        </div>
        <div class="graph-text">
            <div class="info-container" *ngIf="!this.graphHelper.isAddozDevice(keyVal.key) && !this.graphHelper.isVayyarDevice(keyVal.key, null,  sensor) && !this.graphHelper.isSosButtonDevice(keyVal.key)">
                <div class="info-img-holder">
                    <img class="graph-info-img" [src]="'assets/images/settings.png'" alt="measurement type icon" />
                </div>
                <div class="statistics-holder">
                    <p class="statistics-item">{{ "CARE_FACILITY.HIGHEST" | translate }} <span id="{{keyVal.key}}-{{sensor}}-maxVal">{{maxVal}}</span></p>
                    <p class="statistics-item">{{ "CARE_FACILITY.AVERAGE" | translate }} <span id="{{keyVal.key}}-{{sensor}}-meanVal">{{meanVal}}</span></p>
                    <p class="statistics-item">{{ "CARE_FACILITY.LOWEST" | translate }} <span id="{{keyVal.key}}-{{sensor}}-minVal">{{minVal}}</span></p>
                </div>
            </div>
            <div class="info-container">
                <div>
                    <p class="hoitaja person-text header-text">{{ 'FORM_VALIDATION.FILTER_RESULTS' | translate }}</p>

                    <mat-form-field color="primary" ngDefaultControl appearance="outline" class="mat-form-field-fluid filter-select">
                        <mat-label class="select-label">{{ 'FORM_VALIDATION.CHOOSE_A_FILTER_OPTION' | translate}}</mat-label>

                        <mat-select value="{{keyVal.key == 'rpm' || keyVal.key == 'distance' ? 0.15: 48}}" class="select-hours" formControlName="selectHoursFilter" id="{{keyVal.key}}-{{sensor}}-select-hours-filter" (selectionChange)="changeTimeSelection($event, keyVal.key, sensor)">
                            <mat-option *ngIf="keyVal.key == 'rpm' || keyVal.key == 'distance'"  value="0.15">{{ 'FORM_VALIDATION.PAST_15_MINUTES' | translate}}</mat-option>
                            <mat-option value="8">{{ 'FORM_VALIDATION.PAST_8_HOURS' | translate}}</mat-option>
                            <mat-option value="12">{{ 'FORM_VALIDATION.PAST_12_HOURS' | translate}}</mat-option>
                            <mat-option value="24">{{ 'FORM_VALIDATION.PAST_24_HOURS' | translate}}</mat-option>
                            <mat-option *ngIf="keyVal.key != 'rpm' && keyVal.key != 'distance'" value="48">{{ 'FORM_VALIDATION.PAST_48_HOURS' | translate}}</mat-option>
                            <mat-option *ngIf="keyVal.key != 'rpm' && keyVal.key != 'distance'"  value="168">{{ 'FORM_VALIDATION.PAST_7_DAYS' | translate}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div style="margin-bottom: 0.5vw; width: 15vw">
                    <button mat-raised-button (click)="openZoomGraph(keyVal.key, sensor)" class="alarm-button"><mat-icon class="done-icn">zoom_in</mat-icon><span>{{ 'GENERAL.ZOOM_GRAPH' | translate }}</span></button>
                </div>
                <div style="margin-bottom: 0.5vw">
                    <button mat-raised-button (click)="downloadCanvas(keyVal.key, sensor)" class="alarm-button"><mat-icon class="done-icn">cloud_download</mat-icon><span>{{ 'GENERAL.DOWNLOAD_GRAPH' | translate }}</span></button>
                </div>
               <!-- <div class="zoom-btn-wrapper {{ this.graphHelper.isAddozDevice(keyVal.key) || this.graphHelper.isVayyarDevice(keyVal.key, null, sensor) ? 'zoom-addoz' : ''}}">
                    <mat-icon [inline]="true" (click)="openZoomGraph(keyVal.key, sensor)" class="zoom-button">zoom_in</mat-icon>
                </div>
                <div class="download-btn-wrapper" id="target_{{keyVal.key}}_{{sensor}}">
                    <mat-icon [inline]="true" (click)="downloadCanvas($event)"  class="zoom-button ">cloud_download</mat-icon>
                </div>-->
                <div class="row alarm-button-row" *ngIf="keyVal.alarm && isNurse" style="width: 15vw">
                    <button mat-raised-button (click)="openDismissDialog(keyVal.key, sensor)" class="alarm-button"><mat-icon class="done-icn">done</mat-icon><span>{{ "ALARM_DETAILS.DISMISS_ALARM" | translate}}</span></button>
                </div>
            </div>
        </div>
    </div>
</div>
