import {Injectable} from '@angular/core';
import {FTBaseService} from '../core/lib/services/ft-base.service';
import {HttpService} from '../core/lib/services/http.service';

@Injectable()
export class TrackerService extends FTBaseService {

    serviceApi = '/api/v2/yepzon/tags/';


    constructor(httpService: HttpService) {
        super(httpService);
    }

    getStates(trackerId: number) {
        return this.httpService.get(this.serviceApi + trackerId + '/states');
    }
}
