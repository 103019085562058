<div class="nav-info-box-card {{ (this.selectedView == 'card') ? 'card-selected' : '' }}">
	<div class="map-select" (click)="changeView('card');">
		<img class="accordion-icon-hmb" [src]="this.selectedView == 'card' ? '/assets/images/icons/general/card_view_icon_inv.svg' : '/assets/images/icons/general/card_view_icon.svg'"/>
	</div>
</div>
<div class="nav-info-box-card nav-info-map {{ (this.selectedView == 'map') ? 'map-selected' : '' }}">
	<div class="map-select {{ (this.selectedView == 'map') ? 'map-selected' : '' }}" (click)="changeView('map');">
		<img class="accordion-icon-hmb" [src]="this.selectedView == 'map' ? '/assets/images/icons/general/map_inv.svg' : '/assets/images/icons/general/map.svg'"/>
	</div>
</div>


