import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpParams} from '@angular/common/http';
import { throwError} from 'rxjs';
import {ApiConstant} from '../utility/api.constants';

@Injectable()
export class HttpService {

  baseApi: string = ApiConstant.BASE_API;

  constructor(private httpClient: HttpClient) {
  }

  postRequest(url, data, options): any {
    if (options) {
      return this.httpClient.post(this.baseApi + url, data, options);
    }
    return this.httpClient.post(this.baseApi + url, data);
  }


  putRequest(url, data, options = null): any {
    return this.httpClient.put(this.baseApi + url, data, options);
  }


  getRequest(url, options = null) {
    return this.httpClient.get(this.baseApi + url, options);
  }

  deleteRequest(url, options = null) {
    return this.httpClient.delete(this.baseApi + url, options);
  }

  get(url) {
    return this.httpClient.get(this.baseApi + url).toPromise()
        .catch(this.catchError);
  }

  post(url, data) {
    return this.httpClient.post(this.baseApi + url, data).toPromise()
        .catch(this.catchError);
  }

  put(url, data) {
    return this.httpClient.put(this.baseApi + url, data).toPromise()
        .catch(this.catchError);
  }

  delete(url) {
    return this.httpClient.delete(this.baseApi + url).toPromise()
        .catch(this.catchError);
  }

  catchError(error: HttpErrorResponse) {
    console.log(error);

    return throwError(JSON.stringify(error)).toPromise();
  }

}
